<template>
  <div class="my_content">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部" name="999"></el-tab-pane>
      <el-tab-pane label="合格" name="1"></el-tab-pane>
      <el-tab-pane label="不合格" name="0"></el-tab-pane>
    </el-tabs>
    <div class="empty" v-if="historyAchievement.length == 0">
      <div>
        <img src="../../assets/empty_box.png" alt="" />
        <p>暂无数据</p>
      </div>
    </div>
    <div class="list" v-else>
      <div
        class="list_item font14"
        v-for="item in historyAchievement"
        :key="item.id"
      >
        <p class="color33">
          {{ item.skillCategoryFirstName }}-{{
            item.skillCategorySecondName
          }}-{{ item.skillCategoryThirdName }}考试
        </p>
        <p class="color99">考试时间：{{ item.examStartTime }}</p>
        <div class="my_table">
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            class="font14 color33"
          >
            <tr>
              <td style="width: 90px">姓名</td>
              <td style="width: 170px">身份证</td>
              <td style="width: 90px">理论</td>
              <td style="width: 90px">实操</td>
              <td style="width: 90px">综合评审</td>
              <td style="width: 90px">业绩</td>
              <!-- <td style="width: 80px">总分</td> -->
              <td style="width: 90px">合格与否</td>
            </tr>
            <tr>
              <td>{{ item.studentName }}</td>
              <td>{{ item.idCard }}</td>
              <td>{{ item.theoryJoinStatus == "4" ? "免考":item.theoryJoinStatus == "3" ? "缺考" : item.theory == null ? "--": item.theory  }}</td>
              <td>{{ item.practiceJoinStatus == "4" ? "免考":item.practiceJoinStatus == "3" ? "缺考" : item.practice == null ? "--": item.practice}}</td>
              <td>
                <!-- {{ item.comprehensive == null ? "--" : item.comprehensive == -1?'缺考': item.comprehensive}} -->
                 <span>{{ item.comprehensive >= 0 ? item.comprehensive : (item.comprehensive == -10 ? '合格' :(item.comprehensive == -20 ? '不合格' :"--")) }}</span>
              </td>
              <td>{{ item.achievement == null ? "--" : item.achievement }}</td>
              <!-- <td>
                {{
                  (item.theory || 0) +
                  (item.practice || 0) +
                  (item.comprehensive || 0) +
                  (item.achievement || 0)
                }}
              </td> -->
              <td>{{ item.isQualifiedStr }}</td>
            </tr>
          </table>
          <img
            v-if="item.isQualified == 1"
            src="../../assets/user/ispass.png"
            alt=""
          />
          <img v-else src="../../assets/user/isnopass.png" alt="" />
        </div>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {queryAchievementHistory} from "@/util/api";
export default {
  data() {
    return {
      activeName: "999",

      historyAchievement: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    handleClick(tab, event) {
      this.getData();
    },
    getData() {
      queryAchievementHistory({
        isQualified: this.activeName == "999" ? "" : this.activeName,
        domainUrl: window.location.hostname,
      }).then((res) => {
        this.historyAchievement = res.data;
        // // if(this.historyAchievement.theoryJoinStatus == "4")
        // {
        // console.log( this.historyAchievement);

        //   this.historyAchievement.theory = "免考";
        // }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.my_content {
  padding: 30px;
}
.list_item {
  margin-top: 20px;
  p {
    margin-bottom: 20px;
  }
}
.my_table {
  border-radius: 10px;
  border: 1px #d5e1f7 solid;
  position: relative;
  img {
    position: absolute;
    right: 0;
    bottom: -15px;
    width: 73px;
    height: 62px;
  }
  tr:first-of-type {
    background-color: #e9effb;
  }
  td {
    border: 1px #d5e1f7 solid;
    padding: 0 15px;
    height: 45px;
    text-align: center;
  }
}
.line {
  margin: 30px 0;
  border-top: 1px dashed #cccccc;
}
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  width: 950px;
  margin: 0 auto;
  img {
    width: 400px;
    height: 400px;
  }
  p {
    text-align: center;
    font-size: 16px;
    color: #666666;
  }
}
</style>
<style scoped>
.my_content >>> .el-tabs__item {
  color: #999;
}
.my_content >>> .el-tabs__item.is-active {
  color: #5586e0;
}
.my_content >>> .el-tabs__active-bar {
  background-color: #5586e0;
  color: #fff;
}
</style>
